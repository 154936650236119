<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: ResourceActionDialog
Description:This file Contains the functionality to add the resource with respective to type and its quantity in the form
-->
<template>
  <er-dialog
    width="35%"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    @beforeClose="handleCloseDialog('close')"
    @open="handleOpenDialog"
    :title="
      actionToPerform === 'edit'
        ? $t('Resource_edit_resource')
        : $t('Resource_new_resource')
    "
    class="action-on-resource"
    custom-class="resource_dialog"
  >
    <!-- <el-alert v-if="ehm__unhandledErrorMessage" type="warning">
      <template slot="title">
        <ul v-html="ehm__unhandledErrorMessage"></ul>
      </template>
    </el-alert> -->

    <el-form size="small" :model="formResource" ref="formResource">
      <el-form-item
        custom-class="type"
        :label="formStrings.type.label"
        prop="type"
        :error="ekmm__castErrorKeyToLang(ehm__errMessagesObject.type)"
      >
        <el-col :span="24">
          <er-select
            size="small"
            v-model="formResource.type"
            class="select-resource-type"
            value-key="code"
            filterable
            :disabled="actionToPerform === 'edit'"
            :placeholder="formStrings.type.placeholder"
          >
            <el-option
              v-for="(value, key) in resourceTypes"
              :key="key"
              :label="value"
              :value="key"
            ></el-option>
          </er-select>
        </el-col>
      </el-form-item>

      <el-form-item
        :label="formStrings.name.label"
        class="input-name"
        prop="name"
        :error="ekmm__castErrorKeyToLang(ehm__errMessagesObject.name)"
      >
        <el-col :span="24">
          <el-input
            v-model="formResource.name"
            :placeholder="formStrings.name.placeholder"
            size="small"
          ></el-input>
        </el-col>
      </el-form-item>

      <el-form-item
        v-show="formResource.type === 'FEED'"
        :label="formStrings.feed_type.label"
        class="input-feed-type"
        prop="feed_type"
        :error="ekmm__castErrorKeyToLang(ehm__errMessagesObject.feed_type)"
      >
        <el-col :span="24">
          <el-input
            size="small"
            v-model="formResource.feed_type"
            :placeholder="formStrings.feed_type.placeholder"
          ></el-input>
        </el-col>
      </el-form-item>

      <el-form-item
        :label="formStrings.kg_dispensed_time.label"
        class="input-kg-dispensed-time"
        v-show="formResource.type === 'FEED'"
        prop="kg_dispensed_time"
        :error="
          ekmm__castErrorKeyToLang(ehm__errMessagesObject.kg_dispensed_time)
        "
      >
        <el-col :span="24">
          <el-input-number
            style="width: 100%;"
            size="small"
            :controls="false"
            :min="0"
            :precision="0"
            @keypress.native="isNumber($event)"
            v-model="formResource.kg_dispensed_time"
          ></el-input-number>
          <!-- <el-input
            size="small"
            v-model="formResource.kg_dispensed_time"
          ></el-input> -->
        </el-col>
      </el-form-item>

      <el-form-item
        :label="formStrings.units.label"
        prop="units"
        :error="ekmm__castErrorKeyToLang(ehm__errMessagesObject.units)"
      >
        <el-col :span="24">
          <er-select
            size="small"
            class="select-units"
            v-model="formResource.units"
            filterable
            :placeholder="formStrings.units.placeholder"
          >
            <el-option
              v-for="(value, key) in selectUnits"
              :key="key"
              :label="value"
              :value="key"
            ></el-option>
          </er-select>
        </el-col>
      </el-form-item>
    </el-form>

    <layout-toolbar slot="footer" justify="center">
      <er-button
        size="mini"
        btnType="save"
        :showLabel="true"
        :showIcon="true"
        @click="submitResourceDetails"
        :loading="loading"
        :disabled="isReadOnly"
      ></er-button>
      <er-button
        size="mini"
        btnType="cancel"
        :showLabel="true"
        @click="handleCloseDialog('close')"
        :disabled="isReadOnly"
      ></er-button>
    </layout-toolbar>
  </er-dialog>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import errorKeyMapMixin from "@/mixins/errorKeyMapMixin";
import { mapGetters } from "vuex";
import { pondMotherSettings } from "@/constants/settings";
export default {
  mixins: [errorHandlerMixin, errorKeyMapMixin],
  props: {
    resource: {
      default: {
        type: "",
        name: "",
        feed_type: "",
        kg_dispensed_time: 24,
        units: ""
      }
    },
    showDialog: {
      default: false
    },
    actionToPerform: {
      default: ""
    }
  },
  data: function () {
    return {
      formResource: this.resource,
      loading: false,
      newErrObject: {
        type: "",
        name: "",
        feed_type: "",
        kg_dispensed_time: "",
        units: ""
      },
      ehm__errMessagesObject: {
        type: {},
        name: {},
        feed_type: {},
        kg_dispensed_time: {},
        units: {}
      },
      ekmm__backndfieldToFieldNameMap: {
        name: "Comn_resource_name",
        kg_dispensed_time: "Comn_kg_feed",
        feed_type: "Comn_feed_type",
        units: "Comn_units",
        type: "Comn_type"
      },
      ekmm__backndfieldToInvalidMap: {
        name: {
          FIELD_REQUIRED: "Resource_name_not_empty",
          NAME_ALREADY_TAKEN: "resource_already_exists"
        },
        kg_dispensed_time: {
          VALUE_SHOULD_BE_NUMBER: "range_error_10_35"
        },
        units: { INVALID_VALUE: "Stock_unit_qty_required" },
        feed_type: { INVALID_VALUE: "Feed Type is required" },

        type: { Invalid_phone_number: "type-is-required" }
      }
    };
  },
  computed: {
    ...mapGetters("user", {
      isReadOnly: "isReadOnly"
    }),
    dialogVisible: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.handleCloseDialog("close");
      }
    },
    resourceTypes() {
      return {
        FEED: this.$t("Comn_feed"),
        MEDICINE: this.$t("Comn_stock_medicine"),
        MINERAL: this.$t("Comn_mineral"),
        PRO_BIOTIC: this.$t("Medicine_pro_biotic"),
        DO_SUBSTITUTE: this.$t("Comn_stock_do_substitute"),
        WATER_CONDITIONER: this.$t("Comn_stock_water_conditioner"),
        CARBON_SOURCE: this.$t("Comn_stock_carbon_source"),
        DISINFECTANT: this.$t("Comn_stock_disinfectant"),
        FERTILIZER: this.$t("Comn_stock_fertilizer"),
        FEED_ADDITIVES: this.$t("Comn_stock_feed_additives"),
      };
    },
    selectUnits() {
      return this.unitofMaterial[this.formResource.type];
    },
    unitofMaterial() {
      return {
        FEED: {
          KG: this.$t("Comn_kilograms_kg")
        },
        MEDICINE: {
          KG: this.$t("Comn_kilograms_kg"),
          L: this.$t("Comn_litres_l")
        },
        MINERAL: {
          KG: this.$t("Comn_kilograms_kg"),
          L: this.$t("Comn_litres_l")
        },
        PRO_BIOTIC: {
          KG: this.$t("Comn_kilograms_kg"),
          L: this.$t("Comn_litres_l")
        },
        DO_SUBSTITUTE: {
          KG: this.$t("Comn_kilograms_kg"),
          L: this.$t("Comn_litres_l")
        },
        WATER_CONDITIONER: {
          KG: this.$t("Comn_kilograms_kg"),
          L: this.$t("Comn_litres_l")
        },
        CARBON_SOURCE: {
          KG: this.$t("Comn_kilograms_kg"),
          L: this.$t("Comn_litres_l")
        },
        DISINFECTANT: {
          KG: this.$t("Comn_kilograms_kg"),
          L: this.$t("Comn_litres_l")
        },
        FERTILIZER: {
          KG: this.$t("Comn_kilograms_kg"),
          L: this.$t("Comn_litres_l")
        },
        FEED_ADDITIVES: {
          KG: this.$t("Comn_kilograms_kg"),
          L: this.$t("Comn_litres_l")
        },
      };
    },
    formStrings() {
      return {
        units: {
          label: this.$t("Resource_measuring_type"),
          placeholder: this.$t("select_measure_type")
        },
        type: {
          label: this.$t("Resource_resource_type"),
          placeholder: this.$t("select_resource_type")
        },
        kg_dispensed_time: {
          label: this.$t("PM_kg_dispensed_time_sec"),
          placeholder: this.$t("eg_kg_dispensed_time")
        },
        name: {
          label: this.$t("Resource_name"),
          placeholder: this.$t("Resource_name")
        },
        feed_type: {
          label: this.$t("Comn_feed_type"),
          placeholder: this.$t("eg_feed_type")
        }
      };
    }
  },
  methods: {
    isNumber(val) {
      if (val.key === " " || val.key === "." || isNaN(val.key)) {
        return val.preventDefault();
      }
    },
    handleOpenDialog() {
      this.formResource = this.resource;
      this.ehm__errMessagesObject = this.$lodash.cloneDeep(this.newErrObject);

      this.ehm__unhandledErrorMessage = "";
    },
    handleCloseDialog(event) {
      this.$emit("close_dialog", event);
    },
    async submitResourceDetails() {
      this.loading = true;
      console.log("this.formResource", this.formResource);
      const resources = this.$store.getters["resource/getArrResources"];
      resources.map((item, i) => {
        if (!item.hasOwnProperty("feed_type")) item.feed_type = "";
      });
      let isNameAlreadyTaken = false;
      if (this.formResource?.type === "FEED") {
        const feedFuncValue = (acc, x) => {
          console.log("acc, x", acc, x);
          if (
            this.actionToPerform === "edit" &&
            x._id === this.formResource._id
          ) {
            return acc;
          }
          acc.push(
            ["name", "feed_type"]
              .map((key) => x[key].toLowerCase().trim())
              .join("__")
          );
          return acc;
        };
        const existingResourceNames = new Set(
          resources.reduce(feedFuncValue, [])
        );
        isNameAlreadyTaken = existingResourceNames.has(
          ["name", "feed_type"]
            .map((key) => this.formResource[key].toLowerCase().trim())
            .join("__")
        );
        this.formResource.name = this.formResource.name.trim();
        this.formResource.feed_type = this.formResource.feed_type.trim();
      } else {
        const funcValue = (acc, x) => {
          console.log("acc, x", acc, x);
          if (
            this.actionToPerform === "edit" &&
            x._id === this.formResource._id
          ) {
            return acc;
          }
          acc.push(
            ["name"].map((key) => x[key].toLowerCase().trim()).join("__")
          );
          return acc;
        };
        const existingResourceNames = new Set(resources.reduce(funcValue, []));
        isNameAlreadyTaken = existingResourceNames.has(
          ["name"]
            .map((key) => this.formResource[key].toLowerCase().trim())
            .join("__")
        );
        this.formResource.name = this.formResource.name.trim();
      }

      const payload = this.$lodash.cloneDeep(this.formResource);
      this.ehm__errMessagesObject = this.$lodash.cloneDeep(this.newErrObject);

      if (payload.type === "" && payload.name === "" && payload.units === "") {
        this.ehm__errMessagesObject.name = {
          error_code: "FIELD_REQUIRED",
          key: "name"
        };
        this.ehm__errMessagesObject.type = {
          error_code: "FIELD_REQUIRED",
          key: "type"
        };
        this.ehm__errMessagesObject.units = {
          error_code: "FIELD_REQUIRED",
          key: "units"
        };
        this.loading = false;
      } else if (isNameAlreadyTaken) {
        this.ehm__errMessagesObject.name = {
          error_code: "NAME_ALREADY_TAKEN",
          key: "name"
        };

        this.loading = false;
      } else if (payload.type.length === 0) {
        this.ehm__errMessagesObject.type = {
          error_code: "Invalid_phone_number",
          key: "type"
        };
        this.loading = false;
      } else if (payload.name.length === 0) {
        this.ehm__errMessagesObject.name = {
          error_code: "FIELD_REQUIRED",
          key: "name"
        };
        this.loading = false;
      } else if (
        payload.type !== "FEED" ? false : payload.feed_type.length === 0
      ) {
        this.ehm__errMessagesObject.feed_type = {
          error_code: "INVALID_VALUE",
          key: "feed_type"
        };
        this.loading = false;
      } else if (
        payload.type !== "FEED"
          ? false
          : parseInt(payload.kg_dispensed_time) >=
            pondMotherSettings.kg_dispense_time_secs[1] + 1
      ) {
        this.ehm__errMessagesObject.kg_dispensed_time = {
          error_code: "VALUE_SHOULD_BE_NUMBER",
          key: "kg_dispensed_time"
        };

        this.loading = false;
      } else if (
        payload.type !== "FEED"
          ? false
          : parseInt(payload.kg_dispensed_time) <=
            pondMotherSettings.kg_dispense_time_secs[0] - 1
      ) {
        this.ehm__errMessagesObject.kg_dispensed_time = {
          error_code: "VALUE_SHOULD_BE_NUMBER",
          key: "kg_dispensed_time"
        };

        this.loading = false;
      } else if (payload.units.length === 0) {
        this.ehm__errMessagesObject.units = {
          error_code: "INVALID_VALUE",
          key: "units"
        };
        this.loading = false;
      } else {
        try {
          const payload = this.$lodash.cloneDeep(this.formResource);
          if (
            this.formResource.type !== "FEED" &&
            this.actionToPerform === "add"
          ) {
            delete payload.kg_dispensed_time;
            delete payload.feed_type;
          }

          if (
            this.formResource.type !== "FEED" &&
            this.actionToPerform === "edit"
          ) {
            delete payload.kg_dispensed_time;
            delete payload.feed_type;
          }

          this.ehm__unhandledErrorMessage = "";
          const storeAction =
            this.actionToPerform === "edit"
              ? "resource/updateResources"
              : "resource/addResources";

          await this.$store.dispatch(storeAction, payload);
          this.$notify({
            title: this.$t("Usrs_success_msg"),
            message: this.$t(
              this.actionToPerform === "add"
                ? "Resource_details_saved_success"
                : "Resource_details_updated_success"
            ),
            duration: 5000,
            type: "success"
          });

          this.handleCloseDialog("resource-action-" + this.actionToPerform);
        } catch (err) {
          this.ehm__errorMessages(err, true);
        } finally {
          this.loading = false;
        }
      }
    }
  }
};
</script>

<style lang="scss">
.aciton-on-resource {
  .el-dialog {
    @include responsiveProperty(width, 32%, 30%, 30%);
  }
  .el-dialog__body {
    padding: 15px 15px;
  }
  .select-units,
  .select-resource-type {
    width: 100%;
  }
  .el-input-number {
    width: 100%;
  }
}

.resource_dialog {
  background: #fcfcfc;
  border-radius: 10px;

  .el-input--small .el-input__inner {
    font-size: 14px !important;
    @include responsiveProperty(width, 100%, 100%, 100%);
  }

  .el-form-item--small .el-form-item__content,
  .el-form-item--small .el-form-item__label {
    line-height: 25px;
     @include responsiveProperty(font-size, 15px !important, 16px !important, 16px !important);
    @include responsiveProperty(min-width, 200px !important, 210px !important, 190px !important);
  }
}
</style>
