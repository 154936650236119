<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: VendorTab
Description:This file contains the details of the vendors if added , add the new labs and edit the delete the corresponding vendors
-->
<template>
  <el-row
    v-loading="loading"
    element-loading-background="white"
    type="flex"
    justify="center"
  >
    <el-col :span="24" class="tab-vendors" v-if="!loading">
      <el-row type="flex" justify="end" class="action-toolbar">
        <er-button
          btnType="circle_add"
          :showLabel="true"
          :showIcon="true"
          size="mini"
          @click="
            handleClickAction($event, 'add', $lodash.cloneDeep(newVendor))
          "
          :disabled="isReadOnly"
        >
          <slot>
            <p style="text-transform: uppercase">
              {{ $t("Resource_add_vdr") }}
            </p>
          </slot>
        </er-button>
      </el-row>
      <el-row>
        <er-data-tables
          ref="vendorTable"
          :actionColumn="false"
          v-show="!loading"
          :columns="tableColumns"
          type="white-header-table"
          :tableData="vendors"
          uniqueId="vendors-tab"
          :el-table-props="tableProps"
          :key="$i18n.locale"
        >
          <template v-slot:actions="{ row }">
            <er-button
              :showLabel="true"
              :showIcon="true"
              type="text"
              size="mini"
              slot="reference"
              btnType="edit"
              :disabled="isReadOnly"
              @click="
                handleClickAction($event, 'edit', $lodash.cloneDeep(row.data))
              "
            ></er-button>

            <el-divider direction="vertical"></el-divider>
            <er-button
              :showLabel="true"
              :showIcon="true"
              type="text"
              size="mini"
              btnType="delete"
              :disabled="isReadOnly"
              @click="handleRemoveVendor($event, row.data)"
            ></er-button>
          </template>
        </er-data-tables>
      </el-row>

      <component
        :is="`vendorActionDialog`"
        :showDialog="dialogVisible"
        :vendor="selectedVendor"
        :actionToPerform="actionToPerform"
        @close_dialog="closeDialog"
      ></component>
    </el-col>
    <Loader v-else-if="loading"></Loader>
  </el-row>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import vendorActionDialog from "./vendorActionDialog";
import { mapGetters, mapActions } from "vuex";
import notificationMixin from "@/mixins/notificationMixin";
import Loader from "../base/Loader";
export default {
  mixins: [errorHandlerMixin, notificationMixin],
  components: {
    vendorActionDialog,
    Loader
  },
  props: {
    tabData: {
      default: {}
    }
  },
  data: function() {
    return {
      dialogVisible: false,
      selectedVendor: {},
      loading: false,
      newVendor: {
        name: "",
        phone: ""
      },
      actionToPerform: "",
      actionComponentName: "",
      tableProps: {
        height: "calc(100vh - var(--table-gutter))",
        size: "small",
        // border: true,
        align: "center"
      },
      params: {
        get_all: true,
        order_by: "name",
        order_type: "asc",
        type: null
      }
    };
  },
  computed: {
    ...mapGetters("user", {
      isReadOnly: "isReadOnly"
    }),
    tableColumns: function() {
      return {
        name: {
          label: this.$t("Resource_vendor"),
          prop: "name",
          minWidth: "211"
        },
        phone: {
          label: this.$t("Comn_mobile_num"),
          prop: "phone",
          minWidth: "203"
        },
        actions: { label: this.$t("Comn_actions"), minWidth: "170" }
      };
    },
    vendors: function() {
      return this.$store.getters["resource/getArrVendors"];
    }
  },
  async mounted() {
    await this.initComponent();
  },
  methods: {
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    async initComponent() {
      this.loading = true;
      try {
        await this.$store.dispatch("resource/fetchAllVendors", this.params);
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.$nextTick(() => {
          if (this.$refs.vendorTable) {
            this.$refs.vendorTable.$refs.dataTables.$refs.elTable.doLayout();
          }
        });
        this.loading = false;
      }
    },
    initData() {
      this.selectedVendor = this.$lodash.cloneDeep(this.newVendor);
      this.actionToPerform = "";
      this.dialogVisible = false;
    },
    handleClickAction(event, actionToPerform, vendorObj) {
      try {
        this.$gblUAMCanUserEdit(this.tabData);
        this.dialogVisible = true;
        this.actionToPerform = actionToPerform;
        this.selectedVendor = vendorObj;
      } catch (error) {
        this.ehm__errorMessages(error, true);
      }
    },
    closeDialog(event) {
      if (event !== "close") {
         if (this.actionToPerform === 'add') {
          this.mixPanelEventGenerator({ eventName: "Resources - Vendors - Add Vendor - Save" });
        }
        if (this.actionToPerform === 'edit') {
          this.mixPanelEventGenerator({ eventName: "Resources - Vendors - Edit Vendor - Save" });
        }
        this.initComponent();
      }
      this.initData();
    },
    async handleRemoveVendor(event, vendor) {
      try {
        this.$gblUAMCanUserDelete(this.tabData);
        await this.nofm__deleteConfirmation(vendor.name, "table");
        this.loading = true;
        await this.$store.dispatch("resource/deleteVendor", vendor._id);
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: this.$t("Comn_vendor_deleted"),
          duration: 5000,
          type: "success"
        });
        this.mixPanelEventGenerator({ eventName: "Resources - Vendors - Delete" });
        await this.initComponent();
      } catch (error) {
        if (error.response && error.response.data.error_code === "VENDOR_IN_USE_CAN_NOT_BE_DELETED") {
          this.$notify({
            title: this.$t("failed"),
            message: this.$t("vendor_in_use_cant_be_deleted"),
            duration: 5000,
            type: "error"
          });
        }
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss">
.tab-vendors {
  @include responsiveProperty(--table-gutter, 200px, 210px, 215px);
  .pagination-bar {
    .el-pagination__total {
      margin-left: 1.5rem;
    }
  }
  .action-toolbar {
    i {
      font-size: 12px;
    }
  }
  .units-cell {
    text-transform: capitalize;
  }

  .action-btn {
    .img {
      display: inline-flex;
    }
    .material-icons-round {
      font-size: 12px;
    }
  }
}
</style>
